<template>
  <a-spin :spinning="isDetailLoading || isGetResult">
    <div class="container">
      <a-row type="flex" justify="space-between" style="margin-bottom: 10px">
        <a-col>
          <a-button @click="backList">返回列表</a-button>
          <span class="prompt-combo-name">{{ form.promptCombinationName }}</span>
        </a-col>
        <a-space>
          <a-button type="primary" @click="addTask">创建任务</a-button>
          <a-button type="primary" @click="runPromptCombo">运行</a-button>
          <a-button type="primary" @click="openSavePromptComboModal('save')">保存</a-button>
          <a-button @click="showHistory">历史记录</a-button>
        </a-space>
      </a-row>
      <div class="combo-content">
        <div class="combo-list">
          <a-collapse
            defaultActiveKey="1"
            expand-icon-position="right"
            v-for="(item, index) in form.subtaskList"
            :key="item.id"
            style="margin-bottom: 10px"
          >
            <a-collapse-panel key="1">
              <a-row slot="header" type="flex" justify="space-between" class="card-header">
                <div>
                  <template v-if="item.isEditTaskName">
                    <a-input
                      @click.stop=""
                      style="width: 200px"
                      v-model="item.subtaskName"
                      @blur="initOutputParams"
                    ></a-input>
                    <a-icon
                      type="check"
                      class="icon-btn"
                      @click.stop="handleEditTaskName(index)"
                      style="margin-left: 20px"
                    />
                  </template>
                  <template v-else>
                    <div class="task-name">{{ item.subtaskName }}</div>
                    <a-icon
                      type="edit"
                      theme="filled"
                      class="icon-btn"
                      @click.stop="handleEditTaskName(index)"
                      style="margin-left: 20px"
                    />
                  </template>
                </div>
                <div class="delete-wrapper" @click.stop="deleteTask(index)" v-show="form.subtaskList.length > 1">
                  <a-icon type="delete" theme="filled" />
                  <span>删除任务</span>
                </div>
              </a-row>
              <div>
                <a-form-model>
                  <a-form-model-item label="参数类型">
                    <a-radio-group
                      name="radioGroup"
                      v-model="item.promptIdType"
                      @change="() => handleParamTypeChange(item.promptIdType, index)"
                    >
                      <a-radio :value="1"> 预设参数 </a-radio>
                      <a-radio :value="2"> 自定义参数 </a-radio>
                    </a-radio-group>
                    <div v-if="item.promptIdType === 1">
                      <a-space>
                        <a-input style="width: 250px" read-only :value="item.promptName"></a-input>
                        <a-button @click="openPromptSelectModal(index)" type="primary">选择prompt</a-button>
                      </a-space>
                    </div>
                    <a-space class="input-wrapper">
                      <a-input-group compact style="text-wrap: nowrap">
                        <a-input addon-before="模型" style="width: 50px; top: 0" />
                        <a-tree-select
                          v-model="item.aiModel"
                          style="width: 200px"
                          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                          :tree-data="promptList"
                          placeholder="请选择模型"
                          tree-default-expand-all
                        >
                        </a-tree-select>
                      </a-input-group>
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <div>参数填写范围：50-10000</div>
                          <div>此参数会影响输出结果的字符数</div>
                        </template>
                        <a-input
                          addon-before="最大的token数量"
                          style="width: 228px"
                          v-model="item.maxTokens"
                          :disabled="item.promptIdType === 1"
                        />
                      </a-tooltip>
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <div>参数填写范围：0-2</div>
                          <div>
                            此参数影响模型输出的随机性，随机性值越高，多次询问的回答更有创意，更可能没有事实依据；随机性值越低，多次询问的回答更可能遇到重复的回答，回答更接近事实
                          </div>
                        </template>
                        <a-input
                          addon-before="随机性"
                          style="width: 165px"
                          v-model="item.temperature"
                          :disabled="item.promptIdType === 1"
                        />
                      </a-tooltip>
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <div>范围：0.1-1</div>
                          <div>此参数指在输出结果时，选择可能性最高的前P个词的概率累积和</div>
                        </template>
                        <a-input
                          addon-before="可能性"
                          style="width: 165px"
                          v-model="item.topP"
                          :disabled="item.promptIdType === 1"
                        />
                      </a-tooltip>
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <div>范围：-2 ～2</div>
                          <div>此参数影响模型输出重复词汇的可能性。数值越高，输出结果的重复词越少</div>
                        </template>
                        <a-input
                          addon-before="频率惩罚"
                          style="width: 180px"
                          v-model="item.frequencyPenalty"
                          :disabled="item.promptIdType === 1"
                        />
                      </a-tooltip>
                    </a-space>
                  </a-form-model-item>
                  <a-form-model-item label="系统提示词">
                    <a-textarea
                      placeholder="由用户输入"
                      rows="6"
                      v-model="item.systemPrompt"
                      @change="() => handlePromptInputChange(index)"
                      :disabled="item.promptIdType === 1"
                    ></a-textarea>
                  </a-form-model-item>
                  <a-form-model-item label="用户提示词">
                    <a-textarea
                      placeholder="由用户输入"
                      rows="6"
                      v-model="item.userPrompt"
                      @change="() => handlePromptInputChange(index)"
                      :disabled="item.promptIdType === 1"
                    ></a-textarea>
                  </a-form-model-item>
                  <a-form-model-item label="参数">
                    <a-collapse defaultActiveKey="1" expand-icon-position="right">
                      <a-collapse-panel key="1" header="第一组">
                        <a-form-model labelAlign="right" class="params-preview">
                          <a-form-model-item v-for="(key, index) in item.variableArr" :key="index">
                            <a-tooltip slot="label" :title="key">
                              <div class="param-label">{{ key }}</div>
                            </a-tooltip>
                            <span>{{ paramsObject[key] }}</span>
                          </a-form-model-item>
                        </a-form-model>
                      </a-collapse-panel>
                    </a-collapse>
                  </a-form-model-item>
                  <a-form-model-item label="输出参数名">
                    <a-input placeholder="请输入" v-model="item.outputVariable" @blur="initOutputParams"></a-input>
                  </a-form-model-item>
                  <a-form-model-item label="结果" v-if="index !== form.subtaskList.length - 1">
                    <a-card size="small" style="height: 200px" class="flex-card">
                      <div slot="title">
                        <a-row type="flex" justify="space-between">
                          <div class="result-info">
                            <span>时长：{{ Number(item.duration).toFixed(0) }}s</span>
                            <span>字符数：{{ item.characters }}</span>
                            <span>tokens：{{ item.responseTokens }}</span>
                          </div>
                          <a-col>
                            <a-icon
                              type="copy"
                              theme="filled"
                              class="icon-btn"
                              v-clipboard:copy="item.responseText"
                              v-clipboard:success="onCopySuccess"
                              v-clipboard:error="onCopyError"
                            />
                          </a-col>
                        </a-row>
                      </div>
                      <pre>{{ item.responseText }}</pre>
                    </a-card>
                  </a-form-model-item>
                </a-form-model>
              </div>
            </a-collapse-panel>
          </a-collapse>

          <a-card size="small" style="height: 300px" class="flex-card">
            <div slot="title">
              <a-row type="flex" justify="space-between">
                <a-col> 结果 </a-col>
              </a-row>
              <a-row type="flex" justify="space-between" style="margin-top: 8px">
                <div class="result-info">
                  <span>时长：{{ Number(resultData.duration).toFixed(0) }}s</span>
                  <span>字符数：{{ resultData.characters }}</span>
                  <span>tokens：{{ resultData.responseTokens }}</span>
                </div>
                <a-col>
                  <a-icon
                    type="edit"
                    theme="filled"
                    class="icon-btn"
                    @click="isEdit = !isEdit"
                    style="margin-right: 20px"
                  />
                  <a-icon
                    type="copy"
                    theme="filled"
                    class="icon-btn"
                    v-clipboard:copy="resultData.responseText"
                    v-clipboard:success="onCopySuccess"
                    v-clipboard:error="onCopyError"
                  />
                </a-col>
              </a-row>
            </div>
            <a-textarea
              v-if="isEdit"
              style="height: 100%"
              placeholder="请输入"
              v-model="resultData.responseText"
            ></a-textarea>
            <pre v-else>{{ resultData.responseText }}</pre>
          </a-card>
        </div>

        <div class="col-flex">
          <a-card title="参数列表" size="small" class="params-list flex-card">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item v-for="(key, index) in form.inputVariableArr" :key="index">
                <a-tooltip slot="label" :title="key">
                  <span class="param-label">{{ key }}</span>
                </a-tooltip>
                <a-input v-model="paramsObject[key]" />
              </a-form-model-item>
            </a-form-model>
          </a-card>
          <a-card title="任务输出参数名列表" size="small" class="params-list output flex-card">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item v-for="(item, index) in outputParams" :key="index">
                <a-tooltip slot="label" :title="item.subtaskName">
                  <span class="param-label">{{ item.subtaskName }}</span>
                </a-tooltip>
                <span
                  style="cursor: pointer"
                  v-clipboard:copy="item.outputVariable"
                  v-clipboard:success="onCopySuccess"
                  v-clipboard:error="onCopyError"
                >
                  <a-tooltip slot="label" title="点击复制">
                    {{ item.outputVariable }}
                  </a-tooltip>
                </span>
              </a-form-model-item>
            </a-form-model>
          </a-card>
        </div>
        <a-card title="历史记录" size="small" class="flex-card history-list" v-show="isHistoryVisible">
          <a-icon slot="extra" type="close" style="cursor: pointer" @click="isHistoryVisible = false" />
          <div class="col-flex">
            <a-spin :spinning="isHistoryListLoading">
              <div v-for="(item, index) in historyList" :key="index" class="history-item" @click="reShow(item.id)">
                <div class="time">{{ item.ctime }}</div>
                <div>by {{ item.creator }}</div>
              </div>
            </a-spin>
            <center style="margin-bottom: 20px" v-if="historyList.length > 0">
              <a-pagination
                size="small"
                simple
                :page-size="historyParams.size"
                v-model="historyParams.page"
                :total="historyParams.total"
                @change="getHistoryList"
              />
            </center>
          </div>
        </a-card>
      </div>

      <PromptSelectModal ref="PromptSelectModal" @success="handleSelectPrompt" />
      <SavePromptComboModal ref="SavePromptComboModal" @success="savePromptComboCallback" />
    </div>
  </a-spin>
</template>

<script>
import api from '@/api/AIGC.js';
import PromptSelectModal from './components/PromptSelectModal';
import _ from 'lodash';
import SavePromptComboModal from './components/SavePromptComboModal.vue';

export default {
  components: { PromptSelectModal, SavePromptComboModal },
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      isDetailLoading: false,
      isGetResult: false,
      isHistoryListLoading: false,
      isEdit: false,
      form: {
        id: '',
        promptCombinationName: undefined,
        subtaskList: [
          {
            id: new Date().getTime(),
            isEditTaskName: false,
            aiModel: undefined,
            promptIdType: 1, // 1 预设 2自定义
            aigcPromptId: undefined,
            frequencyPenalty: undefined,
            maxTokens: undefined,
            outputVariable: undefined,
            promptDesc: undefined,
            promptName: undefined,
            subtaskName: '子任务1',
            systemPrompt: undefined,
            temperature: undefined,
            topP: undefined,
            userPrompt: undefined,
            variableArr: [],
            // 结果
            characters: '0',
            duration: '0',
            responseTokens: '0',
            responseText: '',
          },
        ],
        inputVariableArr: [],
      },
      paramsObject: {},
      outputParams: [],
      resultData: {
        characters: '0',
        duration: '0',
        responseTokens: '0',
        responseText: '',
      },

      isHistoryVisible: false,
      historyParams: {
        aigcPromptCombination: '',
        page: 1,
        size: 10,
        total: 0,
      },
      historyList: [],
      promptList: [],
    };
  },
  methods: {
    async handleInit(id) {
      this.form.id = id;
      this.isDetailLoading = true;
      try {
        this.form.subtaskList = [];
        const { code, message, data } = await api.getPromptComboDetail(this.form.id);
        this.isDetailLoading = false;
        if (code === 200) {
          data.subtaskList = data.subtaskList.map((item) => ({
            ...item,
            promptIdType: item.aigcPromptId ? 1 : 2,
            characters: '0',
            duration: '0',
            responseTokens: '0',
            responseText: '',
          }));
          Object.assign(this.form, data);
          this.initOutputParams();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
        this.isDetailLoading = false;
      }
      this.getAllPromptList();
    },
    backList() {
      this.$emit('back');
      // this.$router.push({
      //   name: 'promptComboList',
      // });
    },
    /**
     * 打开保存prompt弹窗
     * @param {string} type save:保存， run:运行
     */
    openSavePromptComboModal(type) {
      this.$refs['SavePromptComboModal'].openModal(_.pick(this.form, ['promptCombinationName']), type);
    },
    async savePromptComboCallback(data, type) {
      this.form.promptCombinationName = data.promptCombinationName;
      const res = await this.savePromptCombo();
      if (!res) return;
      if (type === 'run') {
        this.runPromptCombo(false);
      }
    },
    handleEditTaskName(index) {
      this.$set(this.form.subtaskList[index], 'isEditTaskName', !this.form.subtaskList[index].isEditTaskName);
    },
    /**
     * 参数类型改变
     * @param {number} val 1 预设 2自定义
     * @param {number} index 任务列表索引
     */
    handleParamTypeChange(type, index) {
      this.form.subtaskList[index].userPrompt = undefined;
      this.form.subtaskList[index].systemPrompt = undefined;
      this.form.subtaskList[index].aiModel = undefined;
      this.form.subtaskList[index].maxTokens = undefined;
      this.form.subtaskList[index].frequencyPenalty = undefined;
      this.form.subtaskList[index].temperature = undefined;
      this.form.subtaskList[index].topP = undefined;
      this.form.subtaskList[index].aigcPromptId = undefined;
      this.form.subtaskList[index].promptName = undefined;
      this.form.subtaskList[index].promptDesc = undefined;
      if (type === 2) {
        this.form.subtaskList[index].aiModel = 'gpt-35-turbo-16k';
        this.form.subtaskList[index].frequencyPenalty = '0';
        this.form.subtaskList[index].maxTokens = '500';
        this.form.subtaskList[index].temperature = '0.7';
        this.form.subtaskList[index].topP = '1';
      }
      this.handlePromptInputChange(index);
    },
    openPromptSelectModal(index) {
      this.$refs['PromptSelectModal'].openModal(index);
    },
    /**
     * 选择prompt
     */
    handleSelectPrompt(record, index) {
      this.form.subtaskList[index].aigcPromptId = record.id;
      Object.assign(this.form.subtaskList[index], {
        aigcPromptId: record.id,
        ..._.pick(record, [
          'aiModel',
          'promptName',
          'promptDesc',
          'maxTokens',
          'frequencyPenalty',
          'temperature',
          'topP',
          'systemPrompt',
          'userPrompt',
        ]),
      });
      this.handlePromptInputChange(index);
    },
    initOutputParams() {
      const outputParams = [];
      this.form.subtaskList.forEach((item) => {
        if (item.outputVariable) {
          outputParams.push({ subtaskName: item.subtaskName, outputVariable: item.outputVariable });
        }
      });
      this.outputParams = outputParams;
    },
    /**
     * 统计可填参数
     */
    sumParams() {
      let variableArr = [];
      this.initOutputParams();
      const outputList = this.outputParams.map((item) => item.outputVariable);
      this.form.subtaskList.forEach((item) => {
        variableArr = [...variableArr, ...item.variableArr];
      });
      variableArr = _.uniq(variableArr).filter((item) => !outputList.includes(item));
      const newParamsObject = {};
      variableArr.forEach((key) => {
        newParamsObject[key] = this.paramsObject[key];
      });
      this.paramsObject = newParamsObject;
      this.form.inputVariableArr = variableArr;
    },
    initParams() {
      this.initOutputParams();
      this.sumParams();
    },
    /**
     * prompt输入变化
     * @param {*} index
     */
    handlePromptInputChange(index) {
      const regex = /(?<=\{)(.+?)(?=\})/g;
      // 获取匹配的结果
      const systemResult = (this.form.subtaskList[index].systemPrompt || '').match(regex) || [];
      const userResult = (this.form.subtaskList[index].userPrompt || '').match(regex) || [];
      this.form.subtaskList[index].variableArr = _.uniq([...systemResult, ...userResult]);
      // 重新计算总参数
      this.sumParams();
    },
    /**
     * 创建任务
     */
    addTask() {
      this.form.subtaskList.push({
        id: new Date().getTime(),
        isEditTaskName: false,
        aiModel: '',
        promptIdType: 1, // 1 预设 2自定义
        aigcPromptId: undefined,
        frequencyPenalty: undefined,
        maxTokens: undefined,
        outputVariable: undefined,
        promptDesc: undefined,
        promptName: undefined,
        subtaskName: '子任务' + (this.form.subtaskList.length + 1),
        systemPrompt: undefined,
        temperature: undefined,
        topP: undefined,
        userPrompt: undefined,
        variableArr: [],
        // 结果
        characters: '0',
        duration: '0',
        responseTokens: '0',
        responseText: '',
      });
    },
    /**
     * 删除任务
     * @param {*} index
     */
    deleteTask(index) {
      this.form.subtaskList.splice(index, 1);
      this.initParams();
    },
    /**
     * 保存prompt组合
     * @param {*} hideLoading
     * @return {*}
     */
    async savePromptCombo(hideLoading) {
      // 校验参数
      for (const subTask of this.form.subtaskList) {
        if (!subTask.userPrompt) {
          this.$message.error(`"${subTask.subtaskName}"的用户提示词不能为空`);
          return false;
        }
      }
      const saveApi = this.form.id ? api.updatePromptCombo : api.addPromptCombo;
      try {
        if (!hideLoading) this.isDetailLoading = true;
        const { code, data, message } = await saveApi(this.form);
        this.isDetailLoading = false;
        if (code === 200) {
          if (!this.form.id) {
            this.form.id = data;
            this.handleInit(data);
            // this.$router.replace({ name: 'promptCombo', query: { id: this.form.id } });
          }
          !hideLoading && this.$message.success('保存成功');
          return true;
        } else {
          this.$message.error(message);
          return false;
        }
      } catch (error) {
        console.log('error:', error);
        this.isDetailLoading = false;
        return false;
      }
    },
    /**
     * 运行prompt组合
     * @param {boolean} isSavePrompt 是否保存prompt
     */
    async runPromptCombo(isSavePrompt = true) {
      Object.assign(this.resultData, this.$options.data().resultData);
      this.form.subtaskList.forEach((item) =>
        Object.assign(item, {
          characters: '0',
          duration: '0',
          responseTokens: '0',
          responseText: '',
        })
      );
      if (!this.form.id) {
        this.openSavePromptComboModal('run');
        return;
      }
      this.isGetResult = true;
      if (isSavePrompt) {
        const res = await this.savePromptCombo(true);
        if (!res) {
          this.isGetResult = false;
          return;
        }
      }
      try {
        const { code, data, message } = await api.runPromptCombo({
          id: this.form.id,
          variableInputInfo: this.paramsObject,
        });
        this.isGetResult = false;
        if (code === 200) {
          this.form.subtaskList.forEach((item, index) => {
            item.characters = data.subtaskRunInfo[index].characters;
            item.duration = data.subtaskRunInfo[index].duration;
            item.responseTokens = data.subtaskRunInfo[index].responseTokens;
            item.responseText = data.subtaskRunInfo[index].responseText;
          });
          this.resultData = data;
        } else {
          this.$message.error(message);
        }
        if (this.isHistoryVisible) {
          this.getHistoryList();
        }
      } catch (error) {
        this.isGetResult = false;
      }
    },
    showHistory() {
      this.isHistoryVisible = true;
      this.getHistoryList();
    },
    async getHistoryList(current) {
      this.historyParams.aigcPromptCombinationId = this.form.id;
      this.historyParams.page = current || 1;
      this.isHistoryListLoading = true;
      try {
        const { code, data, message } = await api.getPromptComboHistoryList(this.historyParams);
        this.isHistoryListLoading = false;
        if (code === 200) {
          this.historyList = data.list;
          this.historyParams.total = data.total;
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
        this.isHistoryListLoading = false;
      }
    },
    /**
     * 历史记录回显
     * @param {*} id
     */
    async reShow(id) {
      try {
        this.isDetailLoading = true;
        const { code, data, message } = await api.getPromptComboHistoryDetail(id);
        this.isDetailLoading = false;
        if (code === 200) {
          Object.assign(this.form, _.pick(data, ['promptCombinationName', 'inputVariableArr']));
          Object.assign(this.resultData, _.pick(data, ['characters', 'duration', 'responseTokens', 'responseText']));
          this.paramsObject = data.variableInputInfo;
          this.form.subtaskList = data.subtaskRunInfo.map((item) => ({
            ...item,
            promptIdType: item.aigcPromptId ? 1 : 2,
          }));
          this.initOutputParams();
        } else {
          this.$message.error(message);
        }
      } catch (error) {
        console.log('error:', error);
        this.isDetailLoading = false;
      }
    },
    /**
     * 复制成功
     */
    onCopySuccess() {
      this.$message.success('复制成功');
    },
    /**
     * 复制失败
     */
    onCopyError() {
      this.$message.error('复制失败');
    },
    /**
     * 获取模型列表
     */
    async getAllPromptList() {
      const { data, code, msg } = await api.getAiContentModelList();
      if (code == 0) {
        this.promptList = data.map((v, i) => {
          const _data = {
            title: v.first_category,
            value: v.first_category,
            key: v.first_category,
            selectable: false,
            children: v.list.map((vv, ii) => ({
              title: vv.model_name,
              value: vv.model_name,
              key: vv.model_name,
              description: vv.model_description,
            })),
          };
          return _data;
        });
      } else {
        this.$message.error(msg);
      }
    },
  },
  async mounted() {},
};
</script>

<style scoped lang="less">
::v-deep .flex-card.ant-card {
  display: flex;
  flex-direction: column;
  .ant-card-head {
    background: #fafafa;
  }
  .ant-card-body {
    flex: 1;
    overflow: auto;
  }
  .ant-form-item {
    margin-bottom: 0;
    & + .ant-form-item {
      margin-top: 10px;
    }
  }
}
::v-deep .ant-form-item {
  margin-bottom: 0;
  & + .ant-form-item {
    margin-top: 10px;
  }
}
::v-deep .ant-form-item-label label {
  display: flex;
  justify-content: flex-end;
}

::v-deep .params-preview .ant-form-item {
  display: flex;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-all;
}

.col-flex {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.param-label {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}

.container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
  margin-bottom: -17px;

  .prompt-combo-name {
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  .combo-content {
    flex: 1;
    display: flex;
    overflow: hidden;
    background-color: #f0f2f5;
    margin-left: -18px;
    padding-left: 18px;
  }
  .combo-list {
    flex: 1;
    overflow: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    padding-top: 10px;

    .card-header {
      align-items: center;
    }

    .delete-wrapper {
      color: #ff7875;
      & > span {
        margin-left: 5px;
      }
    }

    .task-name {
      display: inline-block;
      font-weight: bold;
    }

    .input-wrapper {
      flex-wrap: wrap;
      overflow: hidden;
    }

    .result-info {
      & > span + span {
        margin-left: 30px;
      }
    }

    .icon-btn {
      cursor: pointer;
    }
  }
  .params-list {
    width: 300px;
    flex: 1;
    &.output {
      height: 250px;
      flex: none;
      flex-shrink: 0;
    }
  }
  .history-list {
    width: 200px;
    .history-item {
      padding: 5px;
      cursor: pointer;
      &:hover {
        background: #fafafa;
      }
      .time {
        font-weight: bold;
      }
    }
  }
}
</style>
