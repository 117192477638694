<template>
  <a-modal title="选择prompt" v-model="visible" width="800px" :footer="null" @cancel="onClose" :maskClosable="false">
    <div class="data-table">
      <a-table :loading="loading" :columns="columns" :data-source="tableData" :pagination="false" row-key="id">
        <span slot="promptDesc" slot-scope="text, record">
          <a-tooltip :title="record.promptDesc">
            <span>{{ record.promptDesc }}</span>
          </a-tooltip>
        </span>
        <div slot="operation" slot-scope="text, record">
          <a-space>
            <a-button type="link" @click="handleSelect(record)">选择</a-button>
          </a-space>
        </div>
      </a-table>
      <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>
  </a-modal>
</template>

<script>
import api from '@/api/AIGC';

export default {
  data() {
    const columns = [
      {
        title: 'prompt名称',
        dataIndex: 'promptName',
      },
      {
        title: '模型',
        dataIndex: 'aiModel',
      },
      {
        title: '创建人',
        dataIndex: 'creator',
      },
      {
        title: '创建时间',
        dataIndex: 'ctime',
      },
      {
        title: 'prompt描述',
        scopedSlots: { customRender: 'promptDesc' },
        ellipsis: true,
      },
      {
        align: 'center',
        title: '操作',
        width: 100,
        scopedSlots: { customRender: 'operation' },
      },
    ];
    return {
      columns,
      visible: false,
      loading: false,
      form: {
        promptName: undefined,
      },
      tableData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      tempIndex: 0,
    };
  },
  components: {},
  mounted() {
    this.getTableData();
  },
  methods: {
    openModal(index) {
      Object.assign(this.$data, this.$options.data());
      this.tempIndex = index;
      this.visible = true;
      this.getTableData(true);
    },
    onClose() {
      this.visible = false;
    },
    getTableData(isFirst) {
      this.loading = true;
      const params = {
        ...this.form,
        page: isFirst ? 1 : this.pagination.current,
        size: this.pagination.pageSize,
      };
      api
        .getPromptDebugList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.tableData = data?.list;
            this.pagination.current = data?.page;
            this.pagination.total = data?.total;
          } else {
            this.$message.error(`${message}`);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTableData();
    },
    handleSelect(record) {
      this.$emit('success', record, this.tempIndex);
      this.onClose();
    },
  },
};
</script>

<style scoped lang="less">
.search-input {
  width: 200px;
  margin-bottom: 15px;
}
</style>
